import { Link } from "gatsby";
import TimeAgo from "react-timeago";
import React from "react";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import enString from "react-timeago/lib/language-strings/en";

const formatter = buildFormatter(enString);

function BlogPosts({ posts = [], currentPath }) {
  return posts.map(({ node }) => {
    // Ignore resume
    const title = node.frontmatter.title || node.fields.slug;
    return (
      <article className="post" key={node.fields.slug}>
        <div className="post-content">
          <h2 className="post-title">
            <Link to={node.fields.slug} state={{ referrer: currentPath }}>{title}</Link>
          </h2>
          <p
            dangerouslySetInnerHTML={{
              __html: node.frontmatter.description || node.excerpt,
            }}
          />
          <span className="post-date">
            <span style={{ fontFamily: `SUNRIZE, "Noto Sans", sans-serif` }}>{node.frontmatter.author}</span> wrote <TimeAgo date={node.frontmatter.date} formatter={formatter} />
          </span>
          <div className="tagging">
            {node.frontmatter.tag.map((t) => {
              return (
                <Link to={`/tags/${t}`} key={t.toString()} className="tagging__item">
                  #{t}
                </Link>
              );
            })}
          </div>
        </div>
        <Link
          className="post-thumbnail"
          style={{
            backgroundImage: `url(${node.frontmatter.image.childImageSharp.fluid.src})`,
          }}
          to={node.fields.slug}
          state={{ referrer: currentPath }}
        />
      </article>
    );
  });
}

export default React.memo(BlogPosts);
